/**
 * 账号管理
 */
import React, { useState, useEffect } from "react";
import { Table ,Form, Button, Input, Modal} from "antd" ;
import {get, post} from "../Utils/required";
import { useNavigate } from "react-router-dom";
import api from "../Utils/api";


function AccountManagement() {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useState({domainId:'1'})
  const [emailCount, setEmailCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [content,setContent] = useState("");

  useEffect(() => {
    getEmailList();
    getEmailCount();
  }, [searchParams]); 

  const getEmailList = () => {
    const {user='',...other} = searchParams;
    const params = {
      apikey: '2971704389',
      page: '1',
      size: '100',
      ...other
    }
    get(`${api.getEmailListUrl}?user=${user}`, params)
  .then(response => {
    console.log('User data:', response);
    setList(response?.result || [])
  })
  .catch(error => {
    console.error('Error fetching user data:', error);
  });
  }


  const getEmailCount = () => {
    const {user='',...other} = searchParams;
    const params = {
      apikey: '2971704389',
      size: '100',
      ...other
    }
    get(`${api.getEmailCountUrl}?user=${user}`, params)
  .then(response => {
    console.log('setEmailCount:', response);
    setEmailCount(response?.result || 0)
  })
  .catch(error => {
    console.error('Error fetching user data:', error);
  });
  }


  const getContentNew = async(e) => {
    const params = {
      apikey: '2971704389',
    }
    get(`${api.getContentNewUrl}?user=${e.user}`, params)
  .then(response => {
    console.log('setContent:', response);
    setContent(response)
  })
  .catch(error => {
    console.error('Error fetching user data:', error);
  });
  }

  const goToContact = (record) => {
    navigate(`/emailManagement?user=${record?.user}`)
  };
  
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '邮箱账号(user)',
      dataIndex: 'user',
      key: 'user',
      render: (text, record, index) => (
        <a onClick={()=>goToContact(record)}>{record?.user}</a>
    ),
    },
    {
      title: '邮箱密码(pass)',
      dataIndex: 'pass',
      key: 'pass',
    },
    {
      title: '转发到邮箱(forward)',
      dataIndex: 'forward',
      key: 'forward',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
          <a onClick={()=>{
            getContentNew(record);
            setOpen(true);
          }}>最新一封邮件内容</a>
      ),
    },
  ];
  
  
  return (
    <div>
      <div className="search">
      <Form
    name="basic"
    layout="inline"
    initialValues={searchParams}
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 18,
    }}
    style={{
      maxWidth: '100%',
    }}
    onFinish={(e)=>{
      setSearchParams(e)
    }}
  >
    <Form.Item
      label="domainId"
      name="domainId"
    >
      <Input allowClear/>
    </Form.Item>

    <Form.Item
      label="user"
      name="user"
    >
      <Input allowClear/>
    </Form.Item>

    

    <Form.Item
      wrapperCol={{
        offset: 6,
        span: 12,
      }}
    >
      <Button type="primary" htmlType="submit">
        搜索
      </Button>
    </Form.Item>
  </Form>
      </div>
      <div className="box">
      <div>账号数量: {emailCount?.count}</div>
      <Table dataSource={list} columns={columns} pagination={{
          position: ['bottomRight'],
        }}/>
      </div>
      

<Modal
      width={'80%'}
        open={open}
        title="Title"
        onOk={()=>{
          setContent('');
          setOpen(false);
        }}
        onCancel={()=>{
          setContent('');
          setOpen(false);
        }}
      >
        <p>{content}</p>
      </Modal>
    </div>
  );
}

export default AccountManagement;
