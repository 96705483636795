// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import DomainNameManagement from './pages/domainNameManagement';
import AccountManagement from './pages/accountManagement';
import EmailManagement from './pages/emailManagement';
import './App.css';

// 定义三个简单的组件，代表不同的页面
const Home = () => DomainNameManagement;
const About = () => <h2>About Page</h2>;
const Contact = () => <h2>Contact Page</h2>;

function App() {
  return (
    <Router>
      <div className='box'>
        {/* 导航链接 */}
        <div className='left'>
          <nav>
          <ul>
            <li><Link to="/">域名管理</Link></li>
            <li><Link to="/accountManagement">账号管理</Link></li>
            <li><Link to="/emailManagement">邮件管理</Link></li>
          </ul>
        </nav>
        </div>
        
        <div className='right'>
{/* 使用 Routes 替换 Switch */}
        <Routes>
          <Route path="/" element={<DomainNameManagement />} />
          <Route path="/accountManagement" element={<AccountManagement />} />
          <Route path="/emailManagement" element={<EmailManagement />} />
          {/* 定义404页面 */}
          <Route path="*" element={<h2>404 Page Not Found</h2>} />
        </Routes>
        </div>
        
      </div>
    </Router>
  );
}

export default App;
