/**
 * 账号管理
 */
import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { Table, Form, Button, Input, DatePicker,Modal } from "antd";
import { get, post } from "../Utils/required";
import api from "../Utils/api";
import MimeNode from 'emailjs-mime-parser';


function EmailManagement() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const user = urlParams.get('user');
  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useState({user})
  const [emailCount, setEmailCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');

  useEffect(() => {
    getRecvList();
    getRecvCount();
  }, [searchParams]);

  const getRecvList = () => {
    const {user,currentdate,...other} = searchParams;
    const params = {
      apikey: '2971704389',
      asc: 'desc',     //asc | desc
      page: '1',
      size: '100',
      ...other,
      currentdate: currentdate && (currentdate).format('YYYY-MM-DD')
    }
    get(`${api.getRecvListUrl}?user=${searchParams.user}`, params)
      .then(response => {
        setList(response?.result || [])
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }


  const getRecvCount = () => {
    const {user,currentdate, ...other} = searchParams;
    const params = {
      /**
          * 安全密钥，使用header或者param参数都可以
          */
      apikey: '2971704389',
      /**
       * 每页尺寸
       */
      size: '100',
      ...other,
      currentdate: currentdate && (currentdate).format('YYYY-MM-DD')
    }
    get(api.getRecvCountUrl, params)
      .then(response => {
        console.log('setEmailCount:', response);
        setEmailCount(response?.result || 0)
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }


  const getContent = (e) => {
    const params = {
      flag:e.flag,
      apikey:'2971704389'
    }
    get(`${api.getContentUrl}?user=${e.user}`, params)
    .then(response => {
      // let parsedMessage = MimeNode(response);
      handleFileUpload(response);
    })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }
  
   const handleFileUpload = (event) => {
    const lines = event.trim().split('\n');
    const parsedHeader = {};
    lines.forEach(line => {
      const [key, value] = line.split(': ');
      if (key && value) {
        parsedHeader[key.trim()] = value.trim()
      }
    });
    // 获取页面上的容器元素
    const container = document.getElementById('emailContent');
    container.innerHTML = '';
    // 遍历对象的键值对
    for (const [key, value] of Object.entries(parsedHeader)) {
      // 创建一个新的 div 元素
      const div = document.createElement('div');
      
      // 设置 div 的内容，显示键和值
      div.textContent = `${key}: ${value}`;
      
      // 将这个 div 添加到容器中
      container.appendChild(div);
    }
  };

  const columns = [
    {
      title: 'flag',
      dataIndex: 'flag',
      key: 'flag',
    },
    {
      title: 'from',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'user',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
          <a onClick={()=>{
            getContent(record);
            setOpen(true);
          }}>邮件内容</a>
      ),
    },
  ];

 

  return (
    <div>
      <div className="search">
        <Form
          name="basic"
          layout="inline"
          initialValues={searchParams}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 18,
          }}
          style={{
            maxWidth: '100%',
          }}
          onFinish={(e) => {
            console.log("索索的孩子----", e)
            setSearchParams(e)
          }}
        // onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="domainId: "
            name="domainId"
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="邮箱账号ID: "
            name="emailId"
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="发信人邮箱: "
            name="from"
          >
            <Input allowClear />
          </Form.Item>


          <Form.Item
            label="user"
            name="user"
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="日期: "
            name="currentdate"
          >
            <DatePicker format="YYYY-MM-DD"/>
          </Form.Item>



          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 12,
            }}
          >
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="box">
      <div>账号数量: {emailCount?.count}</div>
      <Table dataSource={list} columns={columns} pagination={{
        position: ['bottomRight'],
      }} />
      </div>
      

      <Modal
      width={'80%'}
        open={open}
        title="Title"
        onOk={()=>{
          setEmailContent('');
          setOpen(false);
        }}
        onCancel={()=>{
          setEmailContent('');
          setOpen(false);
        }}
      >
        <div id='emailContent'>
          {emailContent}
        </div>
      </Modal>
    </div>
  );
}

export default EmailManagement;
