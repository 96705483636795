import React, { useState, useEffect } from "react";
import { Table, Form, Input, Button } from "antd" ;
import {get, post} from "../Utils/required";
import api from "../Utils/api";


function DomainNameManagement() {
  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useState({})
  useEffect(() => {
   getDomainList()
  }, [searchParams]); 

  const getDomainList = () => {
    const {user='',...other} = searchParams;
    const params = {
      apikey: '2971704389',
        page: '1',
        size: '100',
        ...other
    }
    get(api.getDomainListUrl, params)
  .then(response => {
    console.log('User data:', response);
    setList(response?.result || [])
  })
  .catch(error => {
    console.error('Error fetching user data:', error);
  });
  }

  
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'domain',
      dataIndex: 'domain',
      key: 'domain',
    },
  ];
  
  
  return (
    <div>
      <div className="search">
      <Form
    name="basic"
    layout="inline"
    initialValues={searchParams}
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 18,
    }}
    style={{
      maxWidth: '100%',
    }}
    onFinish={(e)=>{
      setSearchParams(e)
    }}
  >
    <Form.Item
      label="域名后缀"
      name="domain"
    >
      <Input allowClear/>
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 6,
        span: 12,
      }}
    >
      <Button type="primary" htmlType="submit">
        搜索
      </Button>
    </Form.Item>
  </Form>
      </div>
      <Table dataSource={list} columns={columns} pagination={{
          position: ['bottomRight'],
        }}/>
    </div>
  );
}

export default DomainNameManagement;
