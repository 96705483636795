import axios from 'axios';

// 创建axios实例
const apiClient = axios.create({
  baseURL: 'http://129.211.9.126:8080', // API的基础路径
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json', // 默认请求头
    apikey:'2971704389'
  },
});

// 请求拦截器
apiClient.interceptors.request.use(
  config => {
    // 在请求发送前可以做一些处理，比如添加token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // 将token添加到请求头
    }
    return config;
  },
  error => {
    // 请求错误时的处理
    return Promise.reject(error);
  }
);

// 响应拦截器
apiClient.interceptors.response.use(
  response => {
    // 响应成功时处理
    return response.data; // 直接返回响应数据
  },
  error => {
    // 处理响应错误
    if (error.response) {
      // 请求发出后收到非2xx的响应状态码
      switch (error.response.status) {
        case 401:
          // 401: 未授权，可能需要重新登录
          console.error('Unauthorized, redirecting to login');
          break;
        case 404:
          // 404: 未找到资源
          console.error('Resource not found');
          break;
        case 500:
          // 500: 服务器错误
          console.error('Server error');
          break;
        default:
          console.error('Other error:', error.response.status);
      }
    } else if (error.request) {
      // 请求发出没有收到响应
      console.error('No response received');
    } else {
      // 其他错误
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

// 封装GET请求
export const get = (url, params) => {
  return apiClient({
    method: 'get',
    url,
    params, // GET请求参数
  });
};

// 封装POST请求
export const post = (url, data) => {
  return apiClient({
    method: 'post',
    url,
    data, // POST请求数据
  });
};

// 封装PUT请求
export const put = (url, data) => {
  return apiClient({
    method: 'put',
    url,
    data, // PUT请求数据
  });
};

// 封装DELETE请求
export const del = (url, params) => {
  return apiClient({
    method: 'delete',
    url,
    params, // DELETE请求参数
  });
};
