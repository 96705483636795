const url = 'http://129.211.9.126:8080'
const api = {
    getDomainListUrl: '/api/getDomainList',


    /**
     * 账号管理
     */
    getEmailListUrl: '/api/getEmailList',
    getEmailCountUrl: 'api/getEmailCount',

    /**
     * 邮件管理
     */
    getRecvListUrl: '/api/getRecvList',
    getRecvCountUrl: '/api/getRecvCount',
    getContentUrl: '/api/getContent',
    getContentNewUrl: '/api/getContentNew'
}
export default api